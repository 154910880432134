
import { getCurrentInstance } from "vue"
import Layout from "@components/Layout.vue"

export default {
    name: "UserInfo",
    components : { Layout },

    setup(){
        // @ts-ignore
        const { proxy } = getCurrentInstance()

        return { proxy }
    },
}
